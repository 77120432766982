<template>
     <!-- 待审核 -->
    <div class="fs14">
      待审核
        
    </div>
   
</template>

<script>
export default {
    name: '',
    data(){
        return{

        }
    },

    mounted(){

    },
    methods:{
        
    }
}
</script>

<style scoped>

</style>